import { css } from '@emotion/css';
import { ReactComponent as DashedLine } from '../../images/svg/dashed-line.svg';
import { flex } from '../../shared/shared-styles';
import { Tooltip } from '../../common/components/Tooltip';
import { Div, Text } from '../../shared/components';
import { colors } from '../../shared/styles';
import { useEditorResource } from '../../BriteEditor/use-editor-resource';
import { Segment } from '../providers/segment/Segment';
import { useSegment } from '../providers/segment/use-segment';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { Toolbar } from '../composition/Toolbar';

export const SpaceBlock = () => {
  const toolkit = useToolKit();
  const segment = useSegment();

  const properties = Segment.getProperties(segment);
  const isSelected = Segment.getIsSelected(segment, toolkit);

  const { component, container, attributes } = properties;
  const { data: styles } = useEditorResource('styles');

  const combinedStyles = {
    ...styles?.[component],
    ...attributes?.style,
  };

  const containerStyle = container?.attributes?.style;

  const componentStyles =
    component === 'space'
      ? {
          ...combinedStyles,
          borderTopRightRadius: containerStyle?.borderTopRightRadius || '',
          borderBottomRightRadius: containerStyle?.borderBottomRightRadius || '',
          borderTopLeftRadius: containerStyle?.borderTopLeftRadius || '',
          borderBottomLeftRadius: containerStyle?.borderBottomLeftRadius || '',
        }
      : combinedStyles;
  const hasPageBreak = container?.attributes?.style?.breakAfter === 'page';

  return (
    <>
      <div
        className={css`
          position: relative;
          overflow: hidden;
          height: 100%;
          ${flex('center')}
        `}
        style={componentStyles}
        data-block={true}
      >
        {hasPageBreak ? (
          <PageBreakSpace isSelected={isSelected} combinedStyles={combinedStyles} />
        ) : (
          <DefaultSpace isSelected={isSelected} combinedStyles={combinedStyles} />
        )}
      </div>
      <Toolbar></Toolbar>
    </>
  );
};

const DefaultSpace = ({ isSelected = false, combinedStyles = {} }) =>
  isSelected ? (
    <Tooltip label={`Min Height ${combinedStyles?.minHeight}`}>
      <Div
        className={css`
          outline: 1px dotted rgba(200, 200, 200, 0.7);
          width: 100%;
          height: ${combinedStyles?.minHeight};
          :hover {
            background-color: rgba(150, 150, 150, 0.1);
          }
        `}
      />
    </Tooltip>
  ) : null;

const PageBreakSpace = ({ isSelected = false }) => {
  const Content = () => (
    <>
      <DashedLine />
      <Text
        helper
        bold
        color={colors.gray[400]}
        css={`
          white-space: nowrap;
        `}
      >
        Page Break
      </Text>
      <DashedLine />
    </>
  );

  return isSelected ? (
    <Tooltip label="Page breaks only affect exported guides.">
      <Div
        className={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          gap: 8px;
        `}
      >
        <Content />
      </Div>
    </Tooltip>
  ) : (
    <Div
      className={css`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
      `}
    >
      <Content />
    </Div>
  );
};
