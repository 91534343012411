import { createContext, useMemo } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { CustomAxios } from '../redux/axios/axios';
import { useStore } from '../store-provider/use-store';
import * as uuid from 'uuid';
import { useSearchParams } from '../shared/use-search-params';

export const WebSocketContext = createContext();

const connectionStatus = {
  [ReadyState.CONNECTING]: 'connecting',
  [ReadyState.OPEN]: 'connected',
  [ReadyState.CLOSING]: 'closing',
  [ReadyState.CLOSED]: 'closed',
  [ReadyState.UNINSTANTIATED]: 'uninstantiated',
};

export const WebSocket = ({ children }) => {
  const {
    data: { user, selectedBusiness },
  } = useStore();

  const token = useMemo(() => {
    const token = localStorage.getItem('authHeader');
    return token?.replace('Bearer ', '');
  });

  const queryParams = useSearchParams();
  const socketUrl = useMemo(() => {
    const url = CustomAxios.baseURL();
    const nextUrl = url.replace('http', 'ws');
    if (nextUrl && selectedBusiness?.ID && token) {
      return `${nextUrl}ws/connect`;
    }
    return '';
  });

  const socket = useWebSocket(socketUrl, {
    shouldReconnect: () => true,
    queryParams: { token, businessid: selectedBusiness?.ID },
  });

  const status = connectionStatus?.[socket?.readyState] || 'uninstantiated';

  const send = (eventType, data) => {
    const { Business, ...rest } = user;
    const client = {
      user: rest,
      eventId: uuid.v4(),
      timestamp: Date.now(),
      pathname: window.location.pathname,
      params: queryParams?.params,
    };
    const message = JSON.stringify({ event: eventType, data, client });
    socket?.sendMessage(message);
  };

  return <WebSocketContext.Provider value={{ ...socket, send, status }}>{children}</WebSocketContext.Provider>;
};
