import { createContext } from 'react';
import { getMoveActions, getRemoveActions } from './content-utils';

// (DEV being the user) user-facing methods are found here.
// Functions that that these methods use should be
// added to the content-utils.js file.

export const ContentContext = createContext();

export const Content = ({ children, content, setContent, sendUpdates }) => {
  const value = {
    content,
    sendUpdates,
    setContent,
  };
  return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};

Content.commit = (content, actions) => {
  for (const action of actions) {
    switch (action.type) {
      case 'remove': {
        if (action?.property === 'sections') {
          content.sections = content.sections.filter((section) => section.id !== action?.id);
        } else if (action?.property === 'data') {
          delete content.data[action?.id];
        }
        break;
      }
      case 'update': {
        if (action?.property === 'sections') {
          content.sections = content.sections.map((section) =>
            section.id === action?.id ? { ...section, ...action?.updates } : section
          );
        } else if (action?.property === 'data') {
          content.data[action?.id] = {
            ...content.data[action?.id],
            ...action?.updates,
          };
        }
        break;
      }
      case 'insert': {
        if (action?.property === 'data') {
          content.data[action?.id] = { ...action?.updates };
        }
        break;
      }

      case 'sections': {
        content.sections = [...action.sections];
        break;
      }

      default:
        break;
    }
  }
  return content;
};

Content.dragNDrop = async (sendUpdates, drag) => {
  sendUpdates((old) => {
    const actions = getMoveActions(old, drag);
    const content = Content.commit(old, actions);
    return { content, actions };
  });
};

Content.remove = async (sendUpdates, id) => {
  sendUpdates((old) => {
    const actions = getRemoveActions(old, id);
    const content = Content.commit(old, actions);
    return { content, actions };
  });
};
