import { useStore } from '../store-provider/use-store';

// This hook is used to check if the user has permissions.
// It takes an array of acls and a resourceId as arguments.
// It returns a boolean indicating if the user has the required permissions.
// If the user is a Brite user, it will always return true.
// If the user has the required acls on the specified resource, it will return true.
// If no resourceId is specified, it will use the selectedAcl from the store,
// which is the user's ACLs for the selected business.
export const useAcls = (requiredAcls = [], resourceId = null) => {
  // check if requiredAcls is an array, and if not make it an array
  if (!Array.isArray(requiredAcls)) {
    requiredAcls = [requiredAcls];
  }
  const {
    data: { selectedAcl, isBriteEmployee, acls },
  } = useStore();
  if (isBriteEmployee) {
    return true;
  }

  const businessAcls = (selectedAcl && selectedAcl.Acls) || [];
  const aclList = !resourceId ? businessAcls : businessAcls.concat((acls[resourceId] && acls[resourceId].Acls) || []);

  const hasAcls = requiredAcls.every((requiredAcl) => aclList.includes(requiredAcl));
  return hasAcls;
};
