import { colors } from '../../shared/styles';
import { Segment } from '../providers/segment/Segment';

export const blockContainerDefaultStyle = (isSelected, isDragging) => `
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  ${
    isDragging
      ? `
      outline-offset: -2px;
      outline: 1px dotted ${colors.gray[300]};
      border-radius: 8px;
    `
      : ''
  }
  ${
    isSelected || isDragging
      ? ''
      : `
      :hover {
        .overlay {
          outline: 2px solid ${colors.gray[300]}BB;
        }
      }
  `
  }

  .handle {
    position: absolute;
    top: 0;
    right: 0;
    height: 32px;
    weight: 32px;
    :hover {
      cursor: grab;
    }
  }
  :not(:hover) {
    .handle {
      display: none;
    }
  }
`;

export const blockDefaultStyle = `
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: none;
`;

export const getDroppableProps = (segment, drag) => {
  const index = Segment.getIndex(segment, 'block');
  const blockCount = Segment.countParentsChildren(segment);
  const columnCount = Segment.countParentsChildren(segment, 'row');
  const isFirstBlock = index === 0;
  const isLastBlock = index === blockCount - 1;
  const minRange = isFirstBlock ? 10 : 0;
  const maxRange = isLastBlock ? 90 : 100;
  const isDropEnabled = columnCount > 1 && drag?.isDragging && drag?.type === 'move-block';
  return {
    props: {
      activeRects: {
        top: [10, minRange, 90, 50],
        bottom: [10, 50, 90, maxRange],
      },
      disabled: !isDropEnabled,
    },
    isDropEnabled,
  };
};

export const createDragTile = () => {};
