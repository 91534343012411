import { memo, useMemo } from 'react';
import { Box, Text } from '../../shared/components';
import { colors } from '../../shared/styles';
import { SpaceBlock } from '../blocks/SpaceBlock';
import { Segment } from '../providers/segment/Segment';
import { useSegment } from '../providers/segment/use-segment';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { overlay } from '../utility/styles';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { Droppable } from '../utility/Droppable';
import { useDroppable } from '../utility/useDroppable';
import { blockContainerDefaultStyle, blockDefaultStyle, getDroppableProps } from './block-helpers';

const blocks = {
  space: SpaceBlock,
};

export const Block = memo(() => {
  const toolkit = useToolKit();
  const segment = useSegment();

  const isSelected = Segment.getIsSelected(segment, toolkit);
  const style = Segment.getContainerStyle(segment);

  const selectBlock = () => ToolKit.select(toolkit, segment);

  const drag = ToolKit.getDrag(toolkit);

  const handleDragStart = (event) => {
    event.preventDefault();
    ToolKit.startDrag(toolkit, segment, 'move-block');
  };

  const drop = useMemo(() => getDroppableProps(segment, drag), [segment, drag?.isDragging]);
  const droppable = useDroppable(drop.props);

  const BlockComponent = blocks[segment?.type];

  return (
    <Box css={blockContainerDefaultStyle(isSelected, drop.isDropEnabled)} data-scope="block">
      <Box
        {...droppable.attributes}
        style={style}
        css={blockDefaultStyle}
        draggable={true}
        onDragStart={handleDragStart}
        onClick={selectBlock}
      >
        {segment?.type in blocks ? (
          <BlockComponent />
        ) : (
          <Text
            css={`
              padding: 16px;
            `}
          >
            {segment?.type}
          </Text>
        )}
      </Box>
      <BlockOverlay isSelected={isSelected} />
      <Droppable {...droppable} />
    </Box>
  );
});

const overlayStyles = (isSelected) => `
  pointer-events: none;
  outline-offset: -2px;
  ${isSelected ? `outline: 2px solid ${colors.purple};` : ''}
  ${overlay}
  border-radius: 3px;
`;

const BlockOverlay = ({ isSelected }) => {
  return <Box css={overlayStyles(isSelected)} className="overlay" />;
};
