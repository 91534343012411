export const getAction = (content, id) => {
  if (id in content.data) {
    const data = structuredClone(content.data[id]);
    return {
      property: 'data',
      id,
      old: data,
      data,
      updates: {},
      type: '',
    };
  } else {
    const section = content.sections.find((section) => section.id === id);
    if (section) {
      const data = structuredClone(section);
      return {
        property: 'sections',
        id,
        data,
        old: data,
        updates: {},
        type: '',
      };
    }
  }
  return null;
};

export const getParentAction = (content, id) => {
  const child = content?.data?.[id];
  const parentId = child?.parentId;
  return getAction(content, parentId);
};

export const insertAction = (composition, action) => (composition[action.id] = { ...action, type: 'insert' });
export const updateAction = (composition, id, type, data = null) => {
  const currentType = composition[id]?.type;
  composition[id] = {
    ...(composition[id] || {}),
    type: currentType === 'insert' ? 'insert' : type,
    data: {
      ...(composition?.[id]?.data || {}),
      ...data,
    },
    updates: { ...(composition?.[id]?.updates || {}), ...data },
  };
};

export const removeEmptyActions = (composition) => {
  const emptyList = [];
  for (const key in composition) {
    const action = composition[key];
    if ('children' in action?.data && action?.data.children.length === 0) {
      updateAction(composition, action.id, 'remove', {});
      emptyList.push(action);
    }
  }

  const clearChildFromParent = (action) => {
    if (action.data.type === 'section') {
      return;
    }
    const parent = composition[action.data.parentId];
    const children = parent.data.children.filter((child) => child.id !== action.data.id);
    if (children.length === 0) {
      clearChildFromParent(parent);

      // TODO: UNCOMMENT CODE BELOW
      //  Check with design team if we should
      //  remove a section if it's empty.

      // if (parent.data.type !== 'section') {
      updateAction(composition, parent.id, 'remove', { children });
      // } else {
      //   updateAction(composition, parent.id, 'update', { children });
      // }
    } else {
      updateAction(composition, parent.id, 'update', { children });
    }
  };

  emptyList.forEach(clearChildFromParent);
};
