import { useMemo, useRef } from 'react';
import { colors } from '../shared/styles';
import { Box, BriteLoader } from '../shared/components';
import { Segment } from './providers/segment/Segment';
import { useEditorResource } from '../BriteEditor/use-editor-resource';
import { FontLinks } from '../BriteEditor/utility-components';
import { generateColors } from '../BriteEditor/brite-editor';
import { Section } from './composition/Section';
import { Content } from './providers/content/Content';
import { useEditor } from './utility/useEditor';
import { Cursor } from './utility/Cursor';
import { useToolKit } from './providers/tool-kit/use-tool-kit';
import { ToolKit } from './providers/tool-kit/ToolKit';

export const Editor = (props) => {
  const editorRef = useRef();
  const { content, setContent, sendUpdates, isLoading } = useEditor(props?.course, props?.query);
  const { data: styles } = useEditorResource('styles');

  const toolkit = useToolKit();
  const drag = useMemo(() => ToolKit.getDrag(toolkit), [toolkit]);

  return (
    <>
      <Box
        flex="left start"
        css={`
          width: 100%;
          height: calc(100vh - 100px);
          ${generateColors(styles)}
        `}
      >
        <FontLinks fontURLs={styles?.general?.fontURLs} />
        <Box
          css={`
            position: relative;
            flex-grow: 1;
            background-color: white;
            height: 100%;
            width: 100%;
            overflow: auto;
          `}
          flex="center start"
        >
          {isLoading ? (
            <BriteLoader />
          ) : (
            <Box
              css={`
                width: 100%;
              `}
              ref={editorRef}
            >
              <Content content={content} setContent={setContent} sendUpdates={sendUpdates}>
                {content?.sections?.map((section) => (
                  <Segment key={section?.id} scope="editor" element={editorRef} type="section" id={section?.id}>
                    <Section section={section} root={content?.root} />
                  </Segment>
                ))}
              </Content>
            </Box>
          )}
        </Box>
        <Box
          css={`
            min-width: 200px;
            height: 100%;
            background-color: ${colors.gray[100]};
          `}
        ></Box>
      </Box>
      {drag?.isDragging ? <Cursor drag={drag} /> : null}
    </>
  );
};
