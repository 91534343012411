import { getParentAction, updateAction } from './action-utils';
import { addBlockAdjacent } from './block-utils';
import { addColumnAdjacent } from './column-utils';
import { addRowAdjacent } from './row-utils';

export const compositionTypes = ['section', 'row', 'column'];

export const getComposition = (content, action) => {
  const list = [action];
  let current = action;
  while (current !== null) {
    const parent = getParentAction(content, current.data.id);
    if (parent !== null) {
      list.unshift(parent);
    }
    current = parent;
  }
  return list.reduce(
    (prev, item) => ({
      ...prev,
      [item.data.id]: item,
    }),
    {}
  );
};

export const compileCompositionActions = (composition) => {
  return Object.values(composition).reduce((prev, action) => {
    const { type, data, ...rest } = action;
    if (!!type) {
      return [...prev, { type, ...rest }];
    }
    return prev;
  }, []);
};

export const addToComposition = (props) => {
  if (!compositionTypes.includes(props.destination.data.type)) {
    addBlockAdjacent(props);
  } else if (props.destination.data.type === 'section') {
  } else if (props.destination.data.type === 'row') {
    addRowAdjacent(props);
  } else if (props.destination.data.type === 'column') {
    addColumnAdjacent(props);
  }
};

export const removeFromComposition = (composition, action) => {
  const parent = composition[action.data.parentId];
  const children = parent.data.children.filter((child) => child.id !== action.data.id);
  updateAction(composition, parent.id, 'update', { children });
};
