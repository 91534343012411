import * as uuid from 'uuid';
import { addChild, getChildFromAction } from './child-utils';
import { createColumnAction } from './column-utils';
import { insertAction, updateAction } from './action-utils';

export const createRowAction = (parentId, children) => {
  const id = uuid.v4();
  const row = {
    parentId,
    children,
    id,
    type: 'row',
    properties: {},
  };

  const action = {
    id,
    data: row,
    old: null,
    updates: row,
    property: 'data',
    type: 'insert',
  };

  return action;
};

export const addRowAdjacent = ({ composition, source, destination, edge }) => {
  const parent = composition[destination.data.parentId];

  // Create new row
  const row = createRowAction(parent.id, []);
  insertAction(composition, row);

  // Create new column with child
  const child = getChildFromAction(source);
  const column = createColumnAction(row.id, [child]);
  updateAction(composition, source.id, 'update', { parentId: column.id });
  insertAction(composition, column);

  // Add column to row
  const columnAsChild = getChildFromAction(column);
  const rowChildren = addChild(columnAsChild, row.data.children, { edge, id: destination.data.id });
  updateAction(composition, row.id, 'update', { children: rowChildren });

  // Add row to section
  const rowAsChild = getChildFromAction(row);
  const children = addChild(rowAsChild, parent.data.children, { edge, id: destination.data.id });
  updateAction(composition, parent.id, 'update', { children });
};
