import { useEffect, useState } from 'react';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { getCanDeselect, getScope, migrateV2 } from './editor-utils';
import { useDrop } from './useDrop';
import { useCacheEditorResources } from '../../BriteEditor/use-editor-resource';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useEvent } from '../../shared/use-event';

export const useEditor = (guide, page) => {
  // LOAD RESOURCES
  const isLoadingResources = useCacheEditorResources({ course: guide });

  // PAGE CONTENT
  const [content, setContent] = useState({ ...page?.data?.Content });

  const ws = {
    send: (data) => console.log(data),
  };

  const sendUpdates = (updaterFn) => {
    setContent((old) => {
      const { content, actions } = updaterFn(old);
      ws.send(actions);
      return content;
    });
  };

  useEffect(() => {
    if (!page?.isLoading) {
      const content = migrateV2(page?.data?.Content);
      setContent(content);
    }
  }, [page?.isLoading, page?.data?.ID]);

  // TOOLKIT
  const toolkit = useToolKit();

  useDrop({ toolkit, ws, sendUpdates });

  const deselect = (event) => {
    try {
      const selection = ToolKit.getSelection(toolkit);
      if (!selection?.id) {
        return;
      }

      const scope = getScope(event);
      const canDeselect = getCanDeselect(scope);
      if (canDeselect) {
        return;
      }

      ToolKit.deselect(toolkit);
    } catch (err) {
      console.warn(err);
    }
  };

  const endDrag = () => {
    ToolKit.resetDrag(toolkit);
  };

  useEvent('mousedown', deselect);
  useEvent('mouseup', endDrag);

  // Return
  const isLoading = isLoadingResources || page?.isLoading;

  return {
    content,
    setContent,
    sendUpdates,
    isLoading,
  };
};
