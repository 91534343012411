import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/css';
import {
  ArrowUUpLeft,
  ArrowUUpRight,
  Browser,
  ChartBar,
  Chats,
  DotsThreeOutline,
  Export,
  FirstAidKit,
  GearSix,
  Palette,
  WarningCircle,
} from '@phosphor-icons/react';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { useModal } from '../../../common/hooks/useModal';
import { CourseSettings } from '../../../Courses/CourseSettings';
import { Handout } from '../../../Messaging/Handout';
import HandoutIcon from '../../../Messaging/handout-icon-editor.png';
import { needsAttention, useQueryAPI, useResource } from '../../../react-query';
import { Button, Box, Dot, Div, DropMenu, Modal, Text, Divider } from '../../../shared/components';
import { animation, container, flex } from '../../../shared/shared-styles';
import { colors, mediaQueryFn } from '../../../shared/styles';
import { useSearchParams } from '../../../shared/use-search-params';
import { useStore } from '../../../store-provider/use-store';
import { DesignStylesModalV2 } from '../modals/design-styles-modal';
import { ExportGuideToPDFModal } from '../modals/ExportGuideToPDFModal';
import { SavePageTemplate } from '../modals/save-page-template';
import { SyncBenefitPackage } from './sync-benefit-package';
import { Tooltip } from '../../../common/components/Tooltip';

const guideDropdownItem = `
  padding: 8px;
  border-radius: 8px;
  ${container.hover}
  svg, img {
    margin-right: 16px;
  }
  .svg-placeholder {
    margin-right: 16px;
    width: 24px;
  }
  width: 250px;
`;

const guideResourceButton = `
  margin: 0 8px;
  svg {
    width: 32px;
  }
  border-radius: 16px;
  ${mediaQueryFn('less-than', '1200px')} {
    span {
      display: none;
    }
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;
  }
  padding: 8px;
  background-color: ${colors.gray[100]};
  :hover {
    background-color: ${colors.gray[200]};
  }
`;

const guideHotkey = `
  padding: 0px 8px;
  border-radius: 4px;
  background: ${colors.gray[200]};
`;

export const CourseHeaderSettings = ({
  course,
  page,
  savePage,
  saveCourse,
  version,
  refetch,
  theme,
  courseLease,
  pageLease,
}) => {
  const history = useHistory();
  const exportGuideToPDFModal = useModal();

  const {
    data: { isBriteEmployee, selectedBusiness },
  } = useStore();

  const { params, updateParams, removeParam } = useSearchParams();

  const [showHandout, setShowHandout] = useState(false);

  const collaborationFF = useFeatureFlagPayload('editor-collaboration')?.value;
  const showPDFDownloadFF = useFeatureFlagPayload('show-pdf-download')?.value;

  const needsAttentionResource = useResource(needsAttention, {
    enabled: false,
    search: {
      business_id: `eq.${course?.BusinessID}`,
      resource_id: `eq.${course?.ID}`,
    },
  });
  const comments = needsAttentionResource?.data || [];

  const validComments = comments?.filter(({ metadata, resolved }) => {
    const thread = comments.find(({ id }) => metadata.threadId === id);
    return !resolved && !thread?.resolved;
  });

  const { data: benefitPackage } = useQueryAPI({
    url: `v1/benefitspackage/${course?.BenefitsPackageID}`,
    enabled: !!course?.BenefitsPackageID,
  });

  useEffect(() => {
    removeParam('courseModal');
  }, []);

  const closeDesignStyles = async () => {
    removeParam('courseModal');
    await refetch();
  };

  const hasBenefitPackage =
    !course?.BenefitsPackageID || course?.BenefitsPackageID !== '00000000-0000-0000-0000-000000000000';

  return (
    <Box
      flex="center"
      css={`
        flex-grow: 1;
        max-width: calc(100% - 48px);
      `}
    >
      <Div
        css={css`
          ${flex('center')}
        `}
      >
        {!courseLease?.hasLease ? (
          <Tooltip label={courseLease?.reason}>
            <Div
              css={css`
                ${flex('right')}
                ${animation('fadeIn', '.1s ease')}
                  z-index: 10000;
                ${mediaQueryFn('less-than', '1200px')} {
                  p {
                    display: none;
                  }
                }
                overflow: hidden;
                background-color: ${colors.purple}DD;
                padding: 8px;
                border-radius: 16px;
                svg {
                  min-width: 32px;
                }
                p {
                  margin: 0 8px;
                }
              `}
            >
              <WarningCircle color="white" />
              <Text
                css={`
                  color: white;
                `}
                bold
                ellipsis
              >
                Editor Settings Disabled
              </Text>
            </Div>
          </Tooltip>
        ) : null}
        {courseLease?.hasLease || isBriteEmployee ? (
          <>
            <DropMenu
              button={
                <Button
                  styles="icon"
                  hoverLabel="Settings"
                  css={`
                    margin: 0 8px;
                    ${mediaQueryFn('less-than', '1200px')} {
                      width: 40px;
                      height: 40px;
                    }
                  `}
                >
                  <DotsThreeOutline />
                </Button>
              }
            >
              <Box css={guideDropdownItem} flex="left" hover onClick={() => updateParams({ courseModal: 'settings' })}>
                <GearSix size={24} />
                <Text label>Guide Settings</Text>
              </Box>
              <Box
                css={`
                  padding: 8px;
                `}
              >
                <Divider />
              </Box>
              <Box
                css={`
                  ${guideDropdownItem}
                  ${!version.canUndo || version.query.isLoading || !pageLease.hasLease
                    ? 'pointer-events: none; opacity: 0.5;'
                    : ''}
                `}
                flex="space-between"
                hover
                onClick={() =>
                  !version.canUndo || version.query.isLoading || !pageLease.hasLease ? {} : version.undo()
                }
              >
                <Box flex="left">
                  <ArrowUUpLeft size={24} />
                  <Text label>Undo</Text>
                </Box>
                <Box
                  css={`
                    gap: 8px;
                  `}
                  flex="right"
                >
                  <Box css={guideHotkey}>
                    <Text caption color={colors.black}>
                      ⌘
                    </Text>
                  </Box>
                  <Box css={guideHotkey}>
                    <Text caption color={colors.black}>
                      Z
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Box css={guideDropdownItem} flex="space-between" hover onClick={() => version.redo()}>
                <Box flex="left">
                  <ArrowUUpRight size={24} />
                  <Text label>Redo</Text>
                </Box>
                <Box
                  css={`
                    gap: 8px;
                  `}
                  flex="right"
                >
                  <Box css={guideHotkey}>
                    <Text caption color={colors.black}>
                      ⌘
                    </Text>
                  </Box>
                  <Box css={guideHotkey}>
                    <Text caption color={colors.black}>
                      Shift
                    </Text>
                  </Box>
                  <Box css={guideHotkey}>
                    <Text caption color={colors.black}>
                      Z
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Box
                css={`
                  padding: 8px;
                `}
              >
                <Divider />
              </Box>
              <Box
                css={guideDropdownItem}
                flex="left"
                hover
                onClick={() => updateParams({ courseModal: 'save-page-template' })}
              >
                <Browser size={24} />
                <Text label>Convert to template</Text>
              </Box>
              <Box css={guideDropdownItem} flex="left" hover onClick={() => setShowHandout(true)}>
                <img src={HandoutIcon} width="24px" height="24px" alt="handout" />
                <Text label>Handout</Text>
              </Box>
              {showPDFDownloadFF && (
                <Box css={guideDropdownItem} flex="left" hover onClick={() => exportGuideToPDFModal.toggle()}>
                  <Export size={24} />
                  <Text label>Export Guide as PDF</Text>
                </Box>
              )}
            </DropMenu>
            <Tooltip label={theme?.Name ? theme?.Name : ''}>
              <Box
                hoverLabel={theme?.Name}
                hover
                css={guideResourceButton}
                flex="center"
                onClick={() => updateParams({ courseModal: 'design-styles' })}
              >
                <Palette size="currentSize" />
                {theme?.ID ? (
                  <Text
                    as="span"
                    label
                    ellipsis
                    css={`
                      margin-left: 8px;
                      max-width: 120px;
                    `}
                  >
                    {theme?.Name}
                  </Text>
                ) : null}
              </Box>
            </Tooltip>
            <Tooltip label={benefitPackage?.Name ? benefitPackage?.Name : ''}>
              <Box
                hover
                css={guideResourceButton}
                flex="center"
                onClick={() => updateParams({ courseModal: 'benefit-package' })}
              >
                <FirstAidKit size="currentSize" />
                {hasBenefitPackage ? (
                  <Text
                    as="span"
                    label
                    ellipsis
                    css={`
                      margin-left: 8px;
                      max-width: 120px;
                    `}
                  >
                    {benefitPackage?.Name}
                  </Text>
                ) : null}
              </Box>
            </Tooltip>
            {/* Guide Analytics are only available for child businesses. */}
            {selectedBusiness?.Type !== 'multi' && selectedBusiness?.Type !== 'carrier' && (
              <Button
                styles="icon"
                onClick={() => history.push(`/${selectedBusiness.ID}/courses/${course.ID}/analytics`)}
                hoverLabel="Analytics"
              >
                <ChartBar size="currentSize" />
              </Button>
            )}
          </>
        ) : null}
        {collaborationFF ? (
          <Dot display={!!validComments?.length} color={colors.purple} spacing={3}>
            <Button
              disabled={!pageLease?.hasLease}
              styles="icon"
              onClick={() => {
                const mode = params?.mode === 'collaborating' ? 'editing' : 'collaborating';
                updateParams({ mode });
              }}
              hoverLabel={
                <Text
                  css={`
                    color: white;
                    strong {
                      margin-left: 16px;
                    }
                  `}
                >
                  Comment Mode <strong>C</strong>
                </Text>
              }
            >
              <Chats color={params.mode === 'collaborating' ? colors.purple : colors.black} />
            </Button>
          </Dot>
        ) : null}
        <SavePageTemplate
          display={params?.courseModal === 'save-page-template'}
          close={() => removeParam('courseModal')}
          page={page}
        />
        <DesignStylesModalV2
          display={params?.courseModal === 'design-styles'}
          onClose={closeDesignStyles}
          saveCourse={saveCourse}
          refetch={refetch}
          course={course}
        />
        {params?.courseModal === 'settings' && (
          <Modal full display={true}>
            <CourseSettings
              course={course}
              updateCourseChanges={saveCourse}
              close={() => removeParam('courseModal')}
              page={page}
            />
          </Modal>
        )}
        {params?.courseModal === 'benefit-package' && (
          <SyncBenefitPackage
            display={params?.courseModal === 'benefit-package'}
            onClose={() => removeParam('courseModal')}
            course={course}
            saveCourse={saveCourse}
            savePage={savePage}
            page={page}
          />
        )}
        {showHandout && (
          <Handout
            setShowBriteGuideHandout={setShowHandout}
            guideURL={`https://britehr.app/${course.VanityURL}`}
            guideID={course.ID}
            selectedCourse={course}
          />
        )}
        {exportGuideToPDFModal.isOpen && (
          <ExportGuideToPDFModal
            guide={course}
            display={exportGuideToPDFModal.isOpen}
            onClose={exportGuideToPDFModal.hide}
          />
        )}
      </Div>
    </Box>
  );
};
