import { getAction, removeEmptyActions, updateAction } from './composition/action-utils';
import { moveBlock } from './composition/block-utils';
import { findAdjecentChildIndex } from './composition/child-utils';
import { compileCompositionActions, getComposition, removeFromComposition } from './composition/composition-utils';

// Functions and values found here are used by
// the methods in the Content.jsx file.

export const moveSection = (content, { source, destination, edge }) => {
  const section = content.sections.find((section) => section.id === source.id);
  const sections = content.sections.filter((section) => section.id !== source.id);
  const index = findAdjecentChildIndex(sections, { id: destination.id, edge });
  sections.splice(index, 0, section);
  const action = {
    type: 'sections',
    sections,
  };
  return [action];
};

export const getMoveActions = (content, drag) => {
  const source = getAction(content, drag.source.id);
  const destination = getAction(content, drag.destination.id);
  if (drag?.type === 'move-block') {
    return moveBlock(content, { source, destination, edge: drag?.edge });
  } else if (drag?.type === 'move-section') {
    return moveSection(content, { source, destination, edge: drag?.edge });
  }
  return [];
};

export const getRemoveActions = (content, id) => {
  const action = getAction(content, id);
  const composition = getComposition(content, action);
  removeFromComposition(composition, action);
  updateAction(composition, action.id, 'remove', {});
  removeEmptyActions(composition);
  const actions = compileCompositionActions(composition);
  return actions;
};
