import { usePopper } from 'react-popper';
import { useResize } from '../../BriteEditor/editor-components/use-resize';
import { Box, Button } from '../../shared/components';
import { Portal } from '../../shared/components/Portal';
import { shadows } from '../../shared/shared-styles';
import { useState } from 'react';
import { DotsThreeVertical, Trash } from '@phosphor-icons/react';
import { Segment } from '../providers/segment/Segment';
import { useSegment } from '../providers/segment/use-segment';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { colors } from '../../shared/styles';

export const Toolbar = ({ children }) => {
  const toolkit = useToolKit();
  const segment = useSegment();

  const isSelected = Segment.getIsSelected(segment, toolkit);

  if (!isSelected) {
    return null;
  }
  return <ToolbarComponent>{children}</ToolbarComponent>;
};

export const ToolbarComponent = ({ children }) => {
  const segment = useSegment();

  // const properties = Segment.getProperties(segment);
  const rowElement = Segment.getElement(segment, 'row');

  const [toolbarPopper, setToolbarPopper] = useState();
  const popper = usePopper(rowElement, toolbarPopper);
  useResize(rowElement, () => popper?.update());

  const handleDelete = () => {
    console.log('delete');
    Segment.remove(segment);
  };

  return (
    <Portal>
      <Box
        data-scope="toolbar"
        ref={setToolbarPopper}
        css={`
          margin: 8px 0;
          padding: 16px;
          border-radius: 8px;
          background-color: ${colors.gray[600]};
          z-index: 1000;
          gap: 4px;
          ${shadows.md}
        `}
        flex="left"
        style={popper.styles.popper}
        {...popper.attributes.popper}
      >
        {children}
        <Button secondary onClick={() => console.log(segment)}>
          {segment?.type}
        </Button>
        <Button styles="icon-dark sm" hoverLabel="Delete" onClick={handleDelete}>
          <Trash size={24} color="currentColor" />
        </Button>
        <Button styles="icon-dark sm" hoverLabel="More">
          <DotsThreeVertical size={24} color="currentColor" />
        </Button>
      </Box>
    </Portal>
  );
};
