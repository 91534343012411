import { memo, useRef } from 'react';
import { Box } from '../../shared/components';
import { useSegment } from '../providers/segment/use-segment';
import { Segment } from '../providers/segment/Segment';
import { Block } from './Block';
import { Droppable } from '../utility/Droppable';
import { useDroppable } from '../utility/useDroppable';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../providers/tool-kit/ToolKit';

export const Column = memo(() => {
  const columnRef = useRef();
  const segment = useSegment();
  const toolkit = useToolKit();
  const drag = ToolKit.getDrag(toolkit);

  const isDropEnabled = drag?.isDragging && drag?.type === 'move-block';
  const droppable = useDroppable({
    disabled: !isDropEnabled,
    activeRects: {
      left: [0, 10, 15, 90],
      right: [85, 10, 100, 90],
    },
  });

  return (
    <Box
      css={`
        position: relative;
        width: 100%;
      `}
      flex="left column"
      ref={columnRef}
      data-scope="column"
      {...droppable.attributes}
    >
      {segment?.data?.children?.map((child, index) => (
        <Segment key={child?.id} scope="column" index={index} element={columnRef} {...child}>
          <Block />
        </Segment>
      ))}
      <Droppable {...droppable} />
    </Box>
  );
});
