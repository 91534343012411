export const initialToolkit = {
  selection: {
    type: '',
    id: '',
    elements: {},
  },
  drag: {
    type: '',
    edge: '',
    isComplete: false,
    isDragging: false,
    source: {},
    destination: {},
  },
};

export const toolKitReducer = (state, action) => {
  switch (action?.type) {
    case 'SET_PROPERTIES': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PROPERTY': {
      return {
        ...state,
        [action?.property]: {
          ...(state?.[action?.property] || {}),
          ...action.payload,
        },
      };
    }
    case 'SET_PROPERTY': {
      return {
        ...state,
        [action?.property]: action.payload,
      };
    }
    case 'RESET_PROPERTY': {
      return {
        ...state,
        [action?.property]: initialToolkit[action?.property],
      };
    }
    default: {
      return state;
    }
  }
};
