import { css } from '@emotion/css';
import {
  ArrowLeft,
  ArrowsClockwise,
  CloudArrowUp,
  CloudCheck,
  CloudSlash,
  CloudSun,
  Eye,
  PaperPlaneTilt,
  WarningCircle,
} from '@phosphor-icons/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { history } from '../../history';
import { useQueryAPI, useResource } from '../../react-query';
import { Button, ConfirmationModal, Div, Modal, Text } from '../../shared/components';
import { textTheme } from '../../shared/components/text';
import { useStateSync } from '../../shared/use-state-sync';
import { colors, mediaQueryFn } from '../../shared/styles';
import { toast } from 'react-toastify';
import { PageList } from './page-list/page-list';
import { Editor } from './pages/editor';
import { useSearchParams } from '../../shared/use-search-params';
import { useAutoSave } from './use-auto-save';
import { LockedPage } from './pages/locked-page';
import { DecisionTool } from './pages/decision-tool';
import { EnrollmentFeedback } from './pages/enrollment-feedback';
import { MultipleChoice } from './pages/multiple-choice';
import { useLease } from './use-lease';
import { v4 as uuidv4 } from 'uuid';
import { CourseHeaderSettings } from './pages/course-header-settings';
import { usePageVersions } from './use-page-versions';
import { usePages } from './page-list/use-pages';
import { useDebounce } from '../../shared/use-debounce';
import { useKeyboard } from '../../Router/use-keyboard';
import { flex } from '../../shared/shared-styles';
import { useQueryClient } from 'react-query';
import { useStore } from '../../store-provider/use-store';
import { CollectGuideType } from '../collect-guide-type';
import { countEvaluationErrors } from '../../BriteEditor/use-brite-editor';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { BriteLoader } from '../../shared/components/brite-loader';
import { EditorDetailsProvider } from '../../BriteEditor/provider/editor-detail-provider';
import { useRequests } from '../../react-query/use-resource';
import { guide } from '../../react-query/resources/guide';
import { usePosthogRegister } from '../../shared/usePosthogRegister';
import { Subscribers } from '../../shared/components/Subscribers';
import { Box } from '../../shared/components/box';
import { Collapse } from '../../common/components/Collapse';
import { Tooltip } from '../../common/components/Tooltip';
import { useModal } from '../../common/hooks/useModal';
import { CreateLinkModal } from '../../Messaging/editor/modals/CreateLinkModal';
import { LaunchGuideModal } from '../../Messaging/editor/modals/LaunchGuideModal';
import { ToolKit } from '../../BriteEditor.v2/providers/tool-kit/ToolKit';
import { EditorProvider } from '../../BriteEditor/editor-provider';

const courseNameStyle = css`
  border-radius: 8px;
  outline: none;
  ${textTheme.h2}
  ${textTheme.ellipsis}
  border: 1px solid transparent;
  outline: 1px solid transparent;
  :hover {
    background-color: ${colors.gray[100]};
  }
  :focus {
    background-color: white;
    border: 1px solid ${colors.gray[300]};
  }
  :disabled {
    border: 1px solid transparent;
  }
`;

export const isUndoCode = (captured) => captured === 'meta+KeyZ' || captured === 'ctrl+KeyZ';
export const isRedoCode = (captured) => captured === 'meta+shift+KeyZ' || captured === 'ctrl+shift+KeyZ';

export const CourseViewer = () => {
  const {
    data: { devMode, selectedBusiness },
    setStore,
  } = useStore();

  const editorV2 = useFeatureFlagPayload('editorV2')?.value;
  const collaborationFF = useFeatureFlagPayload('editor-collaboration')?.value;
  const editorLaunchGuideFF = useFeatureFlagPayload('editor-launch-guide')?.value;

  const containerRef = useRef();
  const editorRef = useRef();
  const queryClient = useQueryClient();

  useEffect(() => {
    // hack to hide the hubspot chat for this page
    if (document && document.getElementById('hubspot-messages-iframe-container')) {
      document.getElementById('hubspot-messages-iframe-container').setAttribute('style', 'display:none !important;');
    }

    return () => {
      if (document && document.getElementById('hubspot-messages-iframe-container')) {
        document
          .getElementById('hubspot-messages-iframe-container')
          .setAttribute('style', 'width: 100px; height: 96px;');
      }
    };
  }, []);

  const [exitLoader, setExitLoader] = useState(false);
  const { courseId, businessId } = useParams();
  const { params, updateParams, removeParam } = useSearchParams();

  const isVendor = Object.keys(params || {}).find((key) => key.toLowerCase() === 'vendor');

  const courseLease = useLease({ type: 'course', id: courseId });
  const { loading, deleteLease } = courseLease;

  const guideResource = useResource(guide);

  const { isLoading, data: course, refetch: refetchCourse } = guideResource?.query;

  const guideRequests = useRequests(guideResource);

  const [courseName, setCourseName] = useStateSync(course?.Name, [course?.Name]);
  const [resetSeed, setResetSeed] = useState('');
  const [saveBeforeQuit, setSaveBeforeQuit] = useState(false);
  const [saveBeforePage, setSaveBeforePage] = useState('');
  const [enrollmentPage, setEnrollmentPage] = useState('');

  const createLinkModal = useModal();
  const launchGuideModal = useModal();

  // Two states for pageId allows us to make sure changes to a page
  // are saved before the actual page is changed.
  const { pageId: paramsPageId } = params;
  const [pageId, setSyncPageId] = useState(paramsPageId);
  const setPageId = (pageId) => {
    setEnrollmentPage('');
    updateParams({ pageId });
  };
  // ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** *****

  const { data: theme, refetch: refetchTheme } = useQueryAPI({
    cacheKey: ['editor'],
    url: `v1/themes/${course.ThemeID}`,
    enabled: !!course.ThemeID,
    retry: 1,
  });

  useEffect(() => {
    setStore('lastGuideId', courseId);
  }, [courseId]);

  useEffect(() => {
    if (course?.BenefitsPackageID && course?.BenefitsPackageID !== '00000000-0000-0000-0000-000000000000') {
      updateParams({ packageId: course?.BenefitsPackageID });
    } else {
      removeParam('packageId');
    }
  }, [course?.BenefitsPackageID]);

  usePosthogRegister(
    {
      courseID: courseId,
      courseName: course.Name,
      courseMode: 'read-write',
      courseEditSessionId: uuidv4(),
      courseGeneratedById: course.GeneratedBy,
      courseThemeId: course.ThemeID, // TODO: if this gets changed while the editor is opening we need to register this
      courseIsPublished: course.IsPublished,
    },
    [course, isLoading]
  );

  const pageQuery = useQueryAPI({
    cacheKey: ['editor'],
    url: `v2/course/${courseId}/pages/${pageId}`,
    enabled: !!pageId,
    retry: 1,
  });

  const { data: page } = pageQuery;

  const pageLease = useLease({ type: 'page', id: pageId, resourceId: courseId }, [pageId]);
  useEffect(() => {
    if (!pageLease?.hasLease && !pageLease?.loading && !!collaborationFF) {
      updateParams({ mode: 'collaborating' });
    } else if (params.mode === undefined) {
      updateParams({ mode: 'editing' });
    }
  }, [pageLease?.loading]);

  const pagesProps = usePages(pageQuery, setPageId);

  const version = usePageVersions(pageQuery, pagesProps.query);

  const showVersionChangeToast = useDebounce(
    (undoCode, redoCode) => {
      const message = undoCode ? 'Undo is unavailable' : redoCode && 'Redo is unavailable';
      toast.warning(message);
    },
    1000,
    { immediateFirst: true }
  );

  const handleKeydown = (captured) => {
    if (isUndoCode(captured) && version.canUndo) {
      version.undo();
    } else if (isRedoCode(captured) && version.canRedo) {
      version.redo();
    } else if (isUndoCode(captured) || isRedoCode(captured)) {
      showVersionChangeToast(isUndoCode(captured), isRedoCode(captured));
    }
  };

  useKeyboard({ keydown: handleKeydown });

  const { hasLease: hasPageLease, loading: loadingPageLease, deleteLease: deletePageLease } = pageLease;

  const { savePage, saveStatus, refreshTheme, setSaveStatus } = useAutoSave(
    pageQuery,
    version,
    hasPageLease,
    !!course?.ThemeID && course?.ThemeID !== '00000000-0000-0000-0000-000000000000'
  );

  useEffect(() => {
    if (saveStatus !== 'saving' && paramsPageId !== pageId) {
      setSyncPageId(paramsPageId);
    }
  }, [paramsPageId, saveStatus]);

  const refetchCourseAndTheme = async () => {
    await refetchCourse();
    await refetchTheme();
  };

  const saveCurrentPageChanges = async (pageId = null) => {
    if (window.unlayer && pageQuery.data?.Type === 'regular') {
      window.unlayer.exportHtml(
        async (data) => {
          await savePage({ Content: data.design, HTMLContent: data.html }, pageId);
        },
        (e) => console.log('e', e)
      );
    } else if (pageQuery?.data?.Type === 'brite-editor') {
      const data = pageQuery?.cache?.data;
      await savePage(
        {
          Content: data?.Content,
          HTMLContent: data?.HTMLContent,
        },
        pageId
      );
      version.query.refetch();
      version.setCurrentIdx(null);
    }
  };

  const exitCourse = async (overrideChangeCheck = false) => {
    if (!overrideChangeCheck && version.canRedo) {
      setSaveBeforeQuit(true);
      return;
    }
    setExitLoader(true);
    saveCurrentPageChanges(pageId);
    await Promise.all([deleteLease(), deletePageLease()]);
    history.replace(`/${businessId}/courses`);
  };

  useEffect(() => {
    if (pageLease.hasExpired || courseLease.hasExpired) {
      exitCourse(true);
    }
  }, [pageLease.hasExpired, courseLease.hasExpired]);

  const saveCourseRecord = useCallback(
    async (updates) => {
      try {
        const putGuide = guide?.utils?.getPut(courseId, {
          ...guideResource?.query?.data,
          ...updates,
        });
        await guideRequests.put(putGuide);
      } catch (err) {
        toast.error(`Error saving guide record...`);
      }
    },
    [course]
  );

  const handlePageIdChange = (value, skipSave = false) => {
    if (version.canRedo) {
      setSaveBeforePage(value);
      return;
    } else {
      if (!skipSave) {
        saveCurrentPageChanges(value);
      }
      setPageId(value);
    }
  };

  const isEditable = (!page?.IsLocked || isVendor) && !pageLease?.loading && (collaborationFF || hasPageLease);

  const refetchAll = async (reset = false) => {
    await queryClient.refetchQueries({ queryKey: ['editor'], type: 'active' });
    if (reset) {
      setResetSeed(new Date().toISOString());
    }
  };

  const updateSmartFieldEvaluations = (value) => {
    const currentEvaluationPage = pagesProps?.query?.data?.find(({ ID }) => ID === page?.ID);
    const currentEvaluationErrors = countEvaluationErrors(currentEvaluationPage?.RenderMetadata?.EvaluatedMergeTags);
    if (currentEvaluationErrors !== value) {
      pagesProps?.query?.refetch();
    }
  };

  const routeToCourse = () => {
    const pathParam = course.VanityURL || course.ID;
    const evDomain = window.location.origin?.includes('admin-frontend-staging')
      ? 'employee-view-staging-tqtafkpv4q-uw.a.run.app'
      : 'britehr.app';

    const url = !devMode
      ? `https://${evDomain}/${pathParam}/${page?.ID}?lt=preview`
      : `http://localhost:3001/${pathParam}/${page?.ID}}?lt=preview`;
    window.open(url, '_blank');
  };

  const pageType = enrollmentPage || page?.Type || 'brite-editor';

  return (
    <EditorDetailsProvider>
      <Modal display={true} full>
        <CollectGuideType course={course} refetchCourse={refetchCourse} />
        <Div
          css={css`
            ${flex('center')}
            padding: 24px 40px;
            gap: 16px;
            border-bottom: 1px solid ${colors.gray[200]};
          `}
        >
          <Div
            css={css`
              ${flex('left')}
              overflow-x: hidden;
            `}
          >
            {exitLoader ? (
              <BriteLoader overlay={false} size={32} weight={12} />
            ) : (
              <Button styles="icon" onClick={() => exitCourse()}>
                <ArrowLeft />
              </Button>
            )}
            <input
              className={courseNameStyle}
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
              onBlur={() => saveCourseRecord({ Name: courseName })}
              disabled={!courseLease?.hasLease}
            />
          </Div>
          {!loading && !courseLease?.loading ? (
            <CourseHeaderSettings
              course={course}
              page={page}
              savePage={savePage}
              saveCourse={saveCourseRecord}
              version={version}
              refetch={refetchCourseAndTheme}
              theme={theme}
              courseLease={courseLease}
              pageLease={pageLease}
            />
          ) : (
            <div
              className={css`
                flex-grow: 1;
                ${flex('center')}
              `}
            >
              <BriteLoader overlay={false} size={40} weight={12} />
            </div>
          )}
          <Div
            css={css`
              ${flex('right')}
            `}
          >
            {(pageType === 'brite-editor' || pageType === 'regular') && (
              <Div
                css={css`
                  ${flex('right')}
                  gap: 16px;
                `}
              >
                {collaborationFF ? <Subscribers resourceType="guide" resourceId={courseId} /> : null}
                <Div>
                  {saveStatus === 'initial' && <CloudSun />}
                  {saveStatus === '' && (
                    <Button styles="icon" onClick={() => saveCurrentPageChanges()} hoverLabel="Save">
                      <CloudArrowUp />
                    </Button>
                  )}
                  {saveStatus === 'saved' && (
                    <Button styles="icon" onClick={() => saveCurrentPageChanges()} hoverLabel="Save">
                      <CloudCheck />
                    </Button>
                  )}
                  {saveStatus === 'saving' && <ArrowsClockwise />}
                  {saveStatus === 'error' && (
                    <Button styles="icon" onClick={() => saveCurrentPageChanges()} hoverLabel="Try Saving Again">
                      <CloudSlash color={colors.red[100]} />
                    </Button>
                  )}
                </Div>
                <Button
                  secondary
                  medium
                  css={css`
                    padding: 8px 16px;
                    gap: 8px;
                  `}
                  onClick={routeToCourse}
                >
                  <Text
                    h4
                    css={css`
                      font-size: 14px;
                      ${mediaQueryFn('less-than', '1200px')} {
                        display: none;
                      }
                    `}
                  >
                    Preview
                  </Text>
                  <Eye size={24} weight="bold" />
                </Button>
                {/* Launch Guide is only available for child businesses and is under a feature flag. */}
                {editorLaunchGuideFF && selectedBusiness?.Type !== 'multi' && selectedBusiness?.Type !== 'carrier' && (
                  <Button
                    primary
                    medium
                    css={css`
                      padding: 8px 16px;
                      gap: 8px;
                    `}
                    onClick={() => (course.VanityURL === '' ? createLinkModal.toggle() : launchGuideModal.toggle())}
                  >
                    <Text
                      h4
                      css={css`
                        font-size: 14px;
                        ${mediaQueryFn('less-than', '1200px')} {
                          display: none;
                        }
                      `}
                      color="#FFFFFF"
                    >
                      Launch
                    </Text>
                    <PaperPlaneTilt color="#FFFFFF" size={24} weight="bold" />
                  </Button>
                )}
              </Div>
            )}
          </Div>
        </Div>
        <Collapse isOpen={params?.mode === 'collaborating'}>
          <div
            className={css`
              ${flex('center')}
              width: 100%;
              min-height: 40px;
              background-color: ${colors.purple};
            `}
          >
            <Tooltip label={!pageLease?.hasLease ? pageLease?.reason : ''}>
              <div
                className={css`
                  ${flex('center')}
                `}
              >
                {!pageLease?.hasLease ? (
                  <Box flex="left middle">
                    <WarningCircle
                      color="white"
                      size={24}
                      className={css`
                        margin-right: 8px;
                      `}
                    />
                    <Text
                      bold
                      color="white"
                      css={`
                        margin-right: 8px;
                      `}
                    >
                      This page is currently view only.
                    </Text>
                  </Box>
                ) : null}
                <Text color="white">You are now in Comment mode.</Text>
                {pageLease?.hasLease ? (
                  <Text
                    css={`
                      text-decoration: underline;
                      color: white;
                      margin-left: 8px;
                      cursor: pointer;
                    `}
                    onClick={() => updateParams({ mode: 'editing' })}
                  >
                    Close
                  </Text>
                ) : null}
              </div>
            </Tooltip>
          </div>
        </Collapse>
        <Div
          css={css`
            display: flex;
            min-height: calc(100% - 140px);
            max-height: calc(100% - ${params?.mode === 'collaborating' ? '140px' : '100px'});
            overflow: hidden;
            transition: max-height 0.4s ease;
          `}
          ref={containerRef}
        >
          <PageList
            pagesProps={pagesProps}
            pageId={paramsPageId}
            setPageId={handlePageIdChange}
            isVendor={isVendor}
            courseLease={courseLease}
            course={course}
            saveCourse={saveCourseRecord}
            setEnrollmentPage={setEnrollmentPage}
            enrollmentPage={enrollmentPage}
          />
          <Div
            className={css`
              position: relative;
              width: 100%;
              height: 100%;
              overflow: hidden;
            `}
          >
            {pageType === 'regular' ? (
              isEditable ? (
                <Editor
                  ref={editorRef}
                  page={page}
                  theme={theme}
                  pageLease={pageLease}
                  setSaveStatus={setSaveStatus}
                  refreshTheme={refreshTheme}
                />
              ) : !loadingPageLease ? (
                <LockedPage page={page} hideVendorMessage={hasPageLease} course={course} />
              ) : null
            ) : pageType === 'brite-editor' ? (
              <Div
                css={css`
                  position: relative;
                  height: 100%;
                `}
              >
                {editorV2 ? (
                  <ToolKit
                    key={resetSeed}
                    query={pageQuery}
                    course={course}
                    saveCourseRecord={saveCourseRecord}
                    savePage={savePage}
                    versionId={version.versionId}
                    pagesQuery={pagesProps?.query}
                    refetchAll={refetchAll}
                    isEditable={isEditable}
                    updateSmartFieldEvaluations={updateSmartFieldEvaluations}
                    pageLease={pageLease}
                  />
                ) : (
                  <EditorProvider
                    key={resetSeed}
                    query={pageQuery}
                    course={course}
                    saveCourseRecord={saveCourseRecord}
                    savePage={savePage}
                    versionId={version.versionId}
                    pagesQuery={pagesProps?.query}
                    refetchAll={refetchAll}
                    isEditable={isEditable}
                    updateSmartFieldEvaluations={updateSmartFieldEvaluations}
                    pageLease={pageLease}
                  />
                )}
              </Div>
            ) : pageType === 'enroll' ? (
              <EnrollmentFeedback
                pagesProps={pagesProps}
                page={page}
                savePage={savePage}
                course={course}
                updateCourse={saveCourseRecord}
                pageLease={pageLease}
                saveCourse={saveCourseRecord}
              />
            ) : page?.Type === 'decision' ? (
              <DecisionTool
                page={page}
                savePage={savePage}
                course={course}
                updateCourse={saveCourseRecord}
                pageLease={pageLease}
              />
            ) : page?.Type === 'question' ? (
              <MultipleChoice page={page} savePage={savePage} pageLease={pageLease} />
            ) : null}
          </Div>
        </Div>
        <ConfirmationModal
          display={saveBeforeQuit}
          title="You have unsaved changes! Would you like to save before leaving?"
          onConfirm={async () => {
            await saveCurrentPageChanges();
            exitCourse(true);
          }}
          onClose={() => exitCourse(true)}
          onExit={() => setSaveBeforeQuit(false)}
          closeText="Discard"
          confirmText="Save Changes"
        />
        <ConfirmationModal
          display={saveBeforePage}
          title="You have unsaved changes! Would you like to save before changing pages?"
          onConfirm={async () => {
            await saveCurrentPageChanges();
            setPageId(saveBeforePage);
            setSaveBeforePage('');
          }}
          onClose={() => {
            setSaveBeforePage('');
            setPageId(saveBeforePage);
          }}
          onExit={() => setSaveBeforePage('')}
          closeText="Discard"
          confirmText="Save Changes"
        />
        {createLinkModal.isOpen && (
          <CreateLinkModal hide={createLinkModal.hide} launchGuide={launchGuideModal.toggle} guide={course} />
        )}
        {launchGuideModal.isOpen && <LaunchGuideModal hide={launchGuideModal.hide} guide={course} />}
      </Modal>
    </EditorDetailsProvider>
  );
};
