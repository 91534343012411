import { memo, useMemo, useRef } from 'react';
import { Box, Button, Text } from '../../shared/components';
import { colors } from '../../shared/styles';
import { Row } from './Row';
import { Segment } from '../providers/segment/Segment';
import { overlay } from '../utility/styles';
import { DotsSixVertical, GearSix, SelectionPlus } from '@phosphor-icons/react';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useSegment } from '../providers/segment/use-segment';
import { useDroppable } from '../utility/useDroppable';
import { Droppable } from '../utility/Droppable';

const containers = {
  row: Row,
};

const sectionCss = (childCount, isDropEnabled) => `
  position: relative;
  ${
    childCount
      ? ''
      : `
        background-color: ${colors.gray[100]};
        border-top: 1px dashed ${colors.gray[200]};
        border-bottom: 1px dashed ${colors.gray[200]};
      `
  }
  ${
    isDropEnabled
      ? `
    outline: 1px dashed ${colors.gray[300]};
  `
      : ''
  }
  width: 100%;
  .hover-settings {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px;
  }
  :not(:hover) {
    .hover-settings {
      display: none;  
    }
  }
  :hover {
    ::after {
      ${overlay}
      border-top: 1px dashed ${colors.gray[300]};
      border-bottom: 1px dashed ${colors.gray[300]};
      pointer-events: none;
    }
  }
`;

const sectionUtils = {
  getStyles: (root, properties) => {
    const sectionStyles = properties?.style || {};
    if (properties?.fullScreenWidth) {
      return {
        container: { ...sectionStyles },
        section: { ...root?.style, ...sectionStyles },
      };
    }
    return {
      container: {},
      section: { ...root?.style, ...sectionStyles },
    };
  },
};

export const Section = memo(({ section, root }) => {
  const sectionRef = useRef();

  const toolkit = useToolKit();
  const segment = useSegment();
  const childCount = section?.children?.length;

  const drag = ToolKit.getDrag(toolkit);

  const styles = useMemo(() => {
    return sectionUtils.getStyles(root, section?.properties);
  }, [root, section?.properties]);

  const handleDrag = (event) => {
    event.preventDefault();
    ToolKit.startDrag(toolkit, segment, 'move-section');
  };

  const isDropEnabled = drag?.isDragging && drag?.type === 'move-section';
  const droppable = useDroppable({
    disabled: !isDropEnabled,
    activeRects: {
      top: [0, 0, 100, 50],
      bottom: [0, 50, 100, 100],
    },
  });

  return (
    <Box
      ref={sectionRef}
      flex="center"
      css={sectionCss(childCount, isDropEnabled)}
      style={styles?.container}
      data-scope="section"
      {...droppable.attributes}
    >
      <Box style={styles?.section}>
        {!section?.children?.length ? (
          <EmptySection />
        ) : (
          section?.children?.map((child, index) => {
            const Child = containers[child.type];
            return (
              <Segment key={child?.id} element={sectionRef} scope="section" index={index} {...child}>
                <Child />
              </Segment>
            );
          })
        )}
      </Box>
      <Box className="hover-settings" flex="right">
        <Button styles="icon sm">
          <GearSix size={24} weight="fill" color="var(--text-body)" />
        </Button>
        <Box
          css={`
            cursor: grab;
          `}
          flex="center"
          draggable={true}
          onDragStart={handleDrag}
        >
          <DotsSixVertical size={24} color="var(--text-body)" />
        </Box>
      </Box>
      <Droppable {...droppable} />
    </Box>
  );
});

export const EmptySection = () => {
  return (
    <Box
      css={`
        width: 100%;
        padding: 32px;
        margin: 16px 0;
        gap: 16px;
      `}
      flex="left"
    >
      <SelectionPlus size={64} color={colors.gray[400]} />
      <Box>
        <Text h4>Empty Section</Text>
        <Text label>Drag N Drop a block here</Text>
      </Box>
    </Box>
  );
};
