import { memo, useMemo, useRef } from 'react';
import { Box } from '../../shared/components';
import { Segment } from '../providers/segment/Segment';
import { useSegment } from '../providers/segment/use-segment';
import { Column } from './Column';
import { useDroppable } from '../utility/useDroppable';
import { Droppable } from '../utility/Droppable';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../providers/tool-kit/ToolKit';

export const Row = memo(() => {
  const rowRef = useRef();
  const segment = useSegment();
  const toolkit = useToolKit();
  const drag = ToolKit.getDrag(toolkit);

  const childCount = Segment.getChildCount(segment);
  const activeRects = useMemo(
    () => ({
      top: childCount === 1 ? [0, 0, 100, 50] : [0, 0, 100, 10],
      bottom: childCount === 1 ? [0, 50, 100, 100] : [0, 90, 100, 100],
    }),
    [childCount]
  );

  const isDropEnabled = drag?.isDragging && drag?.type === 'move-block';
  const droppable = useDroppable({ disabled: !isDropEnabled, activeRects });

  return (
    <Box
      css={`
        position: relative;
        width: 100%;
      `}
      flex="left stretch"
      ref={rowRef}
      data-scope="row"
      {...droppable.attributes}
    >
      {segment?.data?.children?.map((child, index) => (
        <Segment key={child?.id} scope="row" index={index} element={rowRef} {...child}>
          <Column />
        </Segment>
      ))}
      <Droppable {...droppable} />
    </Box>
  );
});
