import * as uuid from 'uuid';

export const migrateV2 = (content) => {
  let next = {
    root: content?.root || {},
    sections: [],
    data: {},
  };

  for (const row of content?.rows || []) {
    const sectionId = uuid.v4();
    const { type, rowId, columns, stackColumns, ...sectionProperties } = row;
    next.sections.push({
      id: sectionId,
      type: 'section',
      properties: { ...sectionProperties },
      children: [{ id: rowId, type: 'row' }],
    });

    let children = [];
    for (const column of columns) {
      const columnId = uuid.v4();
      const { id: blockId, width, component, ...blockProperties } = column;
      let list = [];
      if (column.type === 'list') {
        for (const item of column.list) {
          const { id: blockId, component, ...blockProperties } = item;
          list.push({ type: component, id: blockId });
          next.data[blockId] = {
            id: blockId,
            type: component,
            parentId: columnId,
            properties: {
              ...blockProperties,
            },
          };
        }
      } else {
        list.push({ id: blockId, type: component });
        next.data[blockId] = {
          id: blockId,
          type: component,
          parentId: columnId,
          properties: {
            ...blockProperties,
          },
        };
      }

      const properties = width ? { width } : {};
      children.push({ id: columnId, type: 'column' });
      next.data[columnId] = {
        id: columnId,
        type: 'column',
        properties,
        children: list,
        parentId: rowId,
      };
    }

    next.data[rowId] = {
      properties: { stackColumns },
      children,
      id: rowId,
      type: 'row',
      parentId: sectionId,
    };
  }

  return next;
};

export const getScope = (event) => {
  const blockElement = event.target.closest('[data-scope]');
  if (blockElement) {
    return blockElement.getAttribute('data-scope');
  }
  return null;
};

export const getCanDeselect = (scope) => {
  switch (scope) {
    case 'block':
    case 'toolbar':
      return true;
    default: {
      return false;
    }
  }
};
