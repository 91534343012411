import { MenuItem } from '@material-ui/core';
import { css } from '@emotion/css';
import { CheckCircle, DotsThree } from '@phosphor-icons/react';
import { forwardRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Div, Dropdown, DropMenu, Text } from '../shared/components';
import { Copy } from '../shared/components/copy';
import { animation, flex, scrollbar } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { useSearchParams } from '../shared/use-search-params';
import { useStore } from '../store-provider/use-store';
import { EditorDetailsContext } from './provider/editor-detail-provider';
import { useKnock } from '../Notifications/useKnock';
import { needsAttention, useRequests, useResource } from '../react-query';
import { bulkOptimistic } from '../react-query/use-resource';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { Box } from '../shared/components/box';
import { textTheme } from '../shared/components/text';
import { SlateComment } from '../shared/components/slate-text/SlateComment';
import { convertSlateToComment, subscribeMentions } from '../shared/components/slate-text/utils';
import { useSlateComment } from '../shared/components/slate-text/useSlateComment';
import { Collapse } from '../common/components/Collapse';
import { useAcls } from '../shared/use-acls';
import { WRITE_COURSE } from '../shared/acl-constants';

const sortComments = (list) => {
  return list.sort((a, b) => a?.created_at?.localeCompare(b?.created_at));
};

export const CommentsView = () => {
  const { businessId, courseId } = useParams();
  const {
    params: { pageId },
  } = useSearchParams();

  const needsAttentionResource = useResource(needsAttention, {
    search: {
      business_id: `eq.${businessId}`,
      resource_id: `eq.${courseId}`,
    },
  });

  const needsAttentionRequests = useRequests(needsAttentionResource);

  const query = needsAttentionResource?.query;
  const comments = needsAttentionResource?.data || [];

  const knock = useKnock();

  useEffect(() => {
    query?.refetch();
  }, [knock.updatedAt]);

  const replyRef = useRef();
  const scrollRef = useRef();

  const {
    dispatch,
    editorDetails: { commenting, commentByComponent },
  } = useContext(EditorDetailsContext);

  const validComponents = Object.keys(commentByComponent?.[pageId] || {});

  const { data } = useStore();

  // Does the user have permissions or are they a brite employee?
  const canWrite = useAcls([WRITE_COURSE]) || data?.isBriteEmployee;

  const userId = data?.user?.ID;

  const [threadId, setThreadId] = useState('');
  const [tab, setTab] = useState('comments');

  const filterComment = ({ metadata, resolved }, hasThread) => {
    const isResolvedRelevant = resolved ? tab === 'resolved' : tab === 'comments';
    const isRelevantThread = hasThread ? metadata.threadId === threadId : !metadata?.threadId;
    return isResolvedRelevant && isRelevantThread && metadata?.pageId === pageId;
  };

  const allList = useMemo(() => {
    const filteredComments = comments?.filter((item) => {
      const isResolvedRelevant = item?.resolved ? tab === 'resolved' : tab === 'comments';
      return isResolvedRelevant && item?.metadata?.pageId === pageId && !item?.metadata?.threadId;
    });

    return sortComments(filteredComments);
  });

  const list = commenting?.selectType !== 'component' ? allList : comments.filter((item) => filterComment(item, false));

  const threadsList = useMemo(() => {
    return sortComments(comments)?.reduce((prev, item) => {
      if (item?.metadata?.threadId) {
        return {
          ...prev,
          [item?.metadata?.threadId]: [...(prev?.[item?.metadata?.threadId] || []), item],
        };
      }
      return prev;
    }, {});
  }, [knock?.updatedAt, needsAttentionResource?.query?.data?.length, needsAttentionResource?.query?.dataUpdatedAt]);

  const resetCommenting = () => {
    dispatch({
      type: 'SET',
      key: 'commenting',
      payload: {
        selectType: '',
        componentId: '',
        commentId: '',
      },
    });
  };

  const submitReply = async (e, slateContent) => {
    e?.preventDefault();
    const replyData = convertSlateToComment(slateContent);
    try {
      subscribeMentions('guide', courseId, replyData?.mentions);
      const postConfig = needsAttention.utils.getPost(
        {
          note: replyData?.preview,
          created_by_user_id: data?.user?.ID,
          resource_id: courseId,
          resource_type: 'guide',
          metadata: {
            pageId,
            threadId: commenting?.commentId,
            componentId: commenting?.componentId,
            username: data?.user?.FirstName || data?.user?.Email,
            content: replyData?.content,
            contentSerializer: replyData?.serializer,
          },
        },
        needsAttentionResource?.params
      );
      await needsAttentionRequests?.post(postConfig);
    } catch (err) {
      console.log(err);
    }
  };

  const selectCommentComponent = (comment) => {
    if (commenting?.commentId === comment?.id) {
      dispatch({
        type: 'SET',
        key: 'commenting',
        payload: {
          ...commenting,
          commentId: '',
        },
      });
    } else {
      dispatch({
        type: 'SET',
        key: 'commenting',
        payload: {
          selectType: 'comment',
          componentId: comment?.metadata?.componentId,
          commentId: comment?.id,
        },
      });
    }
  };

  const handleResolveComment = async (e, comment) => {
    e.stopPropagation();
    resetCommenting();
    setThreadId('');
    // manage optimistic updates here since we are deleting multiple items:
    const onRevert = bulkOptimistic(
      needsAttentionResource,
      comments?.map((item) =>
        comment?.id === item?.id || comment?.id === item?.metadata?.threadId
          ? { ...item, resolved: !item?.resolved }
          : item
      )
    );
    try {
      // Remove all comments in thread:
      const threadList = threadsList?.[comment?.id] || [];
      const list = [comment, ...threadList];
      await Promise.allSettled(
        list?.map((item) => {
          const patch = needsAttention.utils.getPatch(
            item?.id,
            {
              ...item,
              resolved: !comment?.resolved,
            },
            needsAttentionResource?.params
          );
          return needsAttentionRequests?.patch({
            ...patch,
            refetch: false,
            optimistic: false,
          });
        })
      );
    } catch (err) {
      onRevert();
      console.warn(err);
    }
  };

  const handleDeleteComment = async (e, comment) => {
    e.stopPropagation();
    resetCommenting();
    setThreadId('');
    // manage optimistic updates here since we are deleting multiple items:
    const onRevert = bulkOptimistic(
      needsAttentionResource,
      comments?.filter((item) => comment?.id !== item?.id && comment?.id !== item?.metadata?.threadId)
    );
    try {
      const threadList = threadsList?.[comment?.id] || [];
      const list = [comment, ...threadList];
      await Promise.allSettled(
        list?.map((item) => {
          const deleteConfig = needsAttention.utils.getDelete(item?.id, needsAttentionResource?.params);
          return needsAttentionRequests?.delete({
            ...deleteConfig,
            refetch: false,
            optimistic: false,
          });
        })
      );
    } catch (err) {
      onRevert();
      console.warn(err);
    }
  };

  const deleteReply = async (e, reply) => {
    e.stopPropagation();
    const deleteConfig = needsAttention.utils.getDelete(reply?.id, needsAttentionResource?.params);
    await needsAttentionRequests?.delete(deleteConfig);
  };

  useEffect(() => {
    if (commenting?.selectType === 'component' && commenting?.commentId) {
      if (tab !== 'comments') {
        setTab('comments');
      }
      setTimeout(() => {
        scrollRef?.current?.scrollIntoView({
          behavior: 'instant',
          block: 'center',
        });
      }, 200);
      // Allow the collapsed thread to expand before scrolling
    }
  }, [commenting?.selectType, commenting?.commentId, scrollRef]);

  return (
    <Div
      css={css`
        height: 100%;
        max-width: 300px;
        width: 300px;
        min-width: 300px;
        background-color: ${colors.gray[100]};
        ${flex('column start')}
        user-select: none;
        ${animation('fadeIn', '.2s ease')}
      `}
      onClick={(e) => e.stopPropagation()}
    >
      <Div
        css={css`
          width: calc(100% - 32px);
          height: 52px;
          max-height: 52px;
          min-height: 52px;
          margin: 0 16px;
          ${flex('space-around end')}
          overflow: hidden;
          border-bottom: 1px solid ${colors.gray[300]};
          button {
            flex-grow: 1;
          }
        `}
      >
        <Button styles={tab === 'comments' ? 'tab-active' : 'tab-inactive'} onClick={() => setTab('comments')}>
          Comments
        </Button>
        <Button styles={tab === 'resolved' ? 'tab-active' : 'tab-inactive'} onClick={() => setTab('resolved')}>
          Resolved
        </Button>
      </Div>

      <Div
        css={css`
          flex-grow: 1;
          width: 100%;
          height: 100%;
          padding: 8px;
          padding-top: 0;
          ${scrollbar.style}
          overflow-y: scroll;
          padding-bottom: 30vh;
        `}
      >
        {!list?.length ? null : (
          <>
            <Div key={knock?.updatedAt}>
              {list.map((item) => (
                <Div
                  key={item?.id}
                  ref={commenting?.commentId === item?.id ? scrollRef : null}
                  css={css`
                    border-radius: 8px;
                    background-color: white;
                    padding: 12px;
                    width: 100%;
                    margin: 8px 0;
                    border: 1px solid transparent;
                    cursor: pointer;
                    ${commenting?.commentId === item?.id
                      ? `outline: 3px solid ${colors.purple};`
                      : `
                        border: 1px solid ${colors.gray[300]};
                        :hover {
                          border: 1px solid transparent;
                          outline: 2px solid ${colors.black};
                        }
                      `}
                  `}
                  onClick={() => selectCommentComponent(item)}
                >
                  <Collapse isOpen={commenting?.commentId === item?.id}>
                    <Div
                      css={css`
                        ${flex('space-between')}
                        margin: 0 -4px;
                        margin-bottom: 8px;
                      `}
                    >
                      <Button
                        styles="icon sm"
                        onClick={(e) => handleResolveComment(e, item)}
                        hoverLabel={item?.resolved ? 'Unresolve' : 'Resolve'}
                        disabled={!canWrite && item?.created_by_user_id !== userId}
                      >
                        <CheckCircle weight={tab === 'resolved' ? 'fill' : 'regular'} color={colors.gray[400]} />
                      </Button>
                      {canWrite || item?.created_by_user_id === userId ? (
                        <DropMenu
                          button={
                            <Button styles="icon sm">
                              <DotsThree color={colors.gray[400]} />
                            </Button>
                          }
                        >
                          <MenuItem onClick={(e) => handleDeleteComment(e, item)}>Delete</MenuItem>
                        </DropMenu>
                      ) : null}
                    </Div>
                  </Collapse>

                  <Text label bold ellipsis>
                    {item?.metadata?.username}
                  </Text>

                  <Comment
                    canWrite={canWrite || item?.created_by_user_id === userId}
                    item={item}
                    commenting={commenting}
                  />

                  <Collapse isOpen={commenting?.commentId === item?.id}>
                    {threadsList?.[item?.id]?.map((item) => (
                      <Div
                        css={css`
                          padding-top: 8px;
                          ${commenting?.highlightId === item?.id
                            ? `
                            margin -2px -16px;
                            padding: 2px 16px;
                            border-radius: 4px;
                            background-color: ${colors.gray[100]};
                          `
                            : ''}
                        `}
                      >
                        <Text
                          label
                          bold
                          css={`
                            flex-grow: 1;
                            max-height: 24px;
                          `}
                          ellipsis
                        >
                          {item?.metadata?.username}
                        </Text>
                        <Comment
                          canWrite={canWrite || item?.created_by_user_id === userId}
                          item={item}
                          commenting={commenting}
                          deleteComment={deleteReply}
                        />
                      </Div>
                    ))}
                    {commenting?.commentId === item?.id ? (
                      <CommentInput ref={replyRef} submitReply={submitReply} />
                    ) : null}
                    {validComponents?.includes(item?.metadata?.componentId) ? null : (
                      <Text
                        css={`
                          color: red;
                        `}
                      >
                        Component does not exist.
                      </Text>
                    )}
                  </Collapse>
                  {commenting?.commentId !== item?.id && threadsList?.[item?.id]?.length ? (
                    <Text
                      link
                      bold
                      css={`
                        color: ${colors.gray[500]};
                        padding: 8px 0;
                      `}
                    >
                      {threadsList?.[item?.id]?.length === 1
                        ? `${threadsList?.[item?.id]?.length} Reply`
                        : `${threadsList?.[item?.id]?.length} Replies`}
                    </Text>
                  ) : null}
                </Div>
              ))}
            </Div>
          </>
        )}
      </Div>
    </Div>
  );
};

const CommentInput = forwardRef((props, ref) => {
  const slateComment = useSlateComment({ onSubmit: props?.submitReply });
  return (
    <Box
      className={css`
        position: relative;
        margin-top: 16px;
        :not(:focus-within) {
          .button {
            display: none;
          }
        }
      `}
      onClick={(e) => e.stopPropagation()}
      ref={ref}
    >
      <SlateComment
        css={`
          flex-grow: 1;
          border-top: 1px solid ${colors.gray[300]};
          .slate-editable {
            padding: 0;
            margin-bottom: 16px;
            margin-top: 8px;
          }
        `}
        placeholder="Write a reply"
        slateComment={slateComment}
        mention={{ width: 280 }}
      />

      <Button
        css={`
          padding: 4px 8px;
          text-transform: none;
        `}
        className="button"
        onClick={slateComment?.onSubmit}
      >
        Post
      </Button>
    </Box>
  );
});

export const Comment = ({ canWrite, item, commenting, deleteComment = null }) => {
  const serializer = item?.metadata?.content ? item?.metadata?.contentSerializer : '';

  const value = useMemo(() => {
    if (serializer === 'slate-to-html') {
      const value = DOMPurify?.sanitize?.(item?.metadata?.content);
      return parse(value);
    }
    return item?.note;
  }, [item?.note]);

  return (
    <Box
      css={`
        position: relative;
        :not(:hover) {
          .hover-button {
            display: none;
          }
        }
        .hover-button {
          position: absolute;
          gap: 8px;
          ${flex('right')}
          top: -8px;
          right: 0;
        }
      `}
    >
      <Copy value={item?.note} stopPropagation={true} disableCopy={commenting?.commentId !== item?.id}>
        {!serializer ? (
          <Text>{value}</Text>
        ) : serializer === 'slate-to-html' ? (
          <Box
            css={`
              p {
                margin: 0;
                ${textTheme?.body}
              }
            `}
          >
            {value}
          </Box>
        ) : null}
      </Copy>
      <Box className="hover-button" onClick={(e) => e.stopPropagation()}>
        <Dropdown
          button={() => (
            <Button
              styles="icon sm"
              css={`
                background-color: rgba(255, 255, 255, 0.8);
                border: 1px solid ${colors.gray[100]};
              `}
            >
              <DotsThree size={24} />
            </Button>
          )}
        >
          {deleteComment !== null && canWrite ? (
            <Box option onClick={(e) => deleteComment(e, item)}>
              Delete
            </Box>
          ) : null}
          <Box option onClick={() => navigator.clipboard.writeText(item.note)}>
            Copy comment
          </Box>
        </Dropdown>
      </Box>
    </Box>
  );
};
