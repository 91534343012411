import { css } from '@emotion/css';

export const Droppable = ({ css: cssString = '', active = false }) => {
  if (!active) {
    return null;
  }
  return (
    <div
      className={css`
        ${cssString}
      `}
    />
  );
};
