import { removeEmptyActions, updateAction } from './action-utils';
import { addChild, getChildFromAction } from './child-utils';
import {
  addToComposition,
  compileCompositionActions,
  getComposition,
  removeFromComposition,
} from './composition-utils';

export const addBlockAdjacent = ({ composition, source, destination, edge }) => {
  const parent = composition[destination.data.parentId];
  const child = getChildFromAction(source);
  const children = addChild(child, parent.data.children, { id: destination.data.id, edge });
  updateAction(composition, parent.id, 'update', { children });
  updateAction(composition, source.id, 'update', { parentId: parent.data.id });
};

export const moveBlock = (content, { source, destination, edge }) => {
  const sourceComposition = getComposition(content, source);
  const destinationComposition = getComposition(content, destination);
  const composition = {
    ...sourceComposition,
    ...destinationComposition,
  };
  removeFromComposition(composition, source);
  addToComposition({ composition, source, destination, edge });
  removeEmptyActions(composition);
  const actions = compileCompositionActions(composition);

  return actions;
};
