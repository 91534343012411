import { useFeatureFlagPayload } from 'posthog-js/react';
import { MultiGuideAnalyticsPage } from './MultiGuideAnalyticsPage';
import { AnalyticsPage } from './AnalyticsPage';

export const AnalyticsRoute = () => {
  const aggregatedGuideAnalyticsFF = useFeatureFlagPayload('aggregated-guide-analytics');

  // TODO: Separate out Parent / Child Account views here as well.
  if (aggregatedGuideAnalyticsFF?.value) {
    return <MultiGuideAnalyticsPage />;
  } else {
    return <AnalyticsPage />;
  }
};
