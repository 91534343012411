import { useEffect } from 'react';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { Content } from '../providers/content/Content';

export const useDrop = ({ ws, toolkit, sendUpdates }) => {
  const drag = ToolKit.getDrag(toolkit);

  const handleDrop = async () => {
    Content.dragNDrop(sendUpdates, drag);
    ToolKit.resetDrag(toolkit);
  };

  useEffect(() => {
    if (drag?.isComplete) {
      handleDrop();
    }
  }, [drag?.isComplete]);
};
