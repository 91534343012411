import { useEffect, useRef } from 'react';
import { useEvent } from '../../shared/use-event';
import { Box } from '../../shared/components';
import { colors } from '../../shared/styles';
import { ComponentIconMap } from '../../BriteEditor/toolbar-menus';

const tileStyle = () => `
  position: fixed;
  width: 52px;
  height: 52px;
  z-index: 1000;
  border-radius: 50%;
  border: 2px solid ${colors.gray[600]};
  filter: drop-shadow(4px 16px 6px ${colors.purple}2);
  pointer-events: none !important;
  background-color: ${colors.gray[600]};
  opacity: .7;
`;

export const Cursor = ({ drag }) => {
  const mouseRef = useRef();
  const handleMove = (event) => {
    mouseRef.current.style.top = event.clientY + 'px';
    mouseRef.current.style.left = event.clientX - 24 + 'px';
  };

  useEvent('mousemove', handleMove);

  useEffect(() => {
    document.body.style.cursor = 'grabbing';
    return () => {
      document.body.style.cursor = 'auto';
    };
  }, []);

  return (
    <Box css={tileStyle()} flex="center" ref={mouseRef}>
      <ComponentIconMap type={drag?.source?.type} size={32} color="white" />
    </Box>
  );
};
