import { css } from '@emotion/css';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { ColumnChart } from 'react-chartkick';
import { Tooltip } from '../../../common/components/Tooltip';
import { BriteLoader } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { EnhancedTable } from '../../EnhancedTable';
import { EmptyState, EmptyStateSmall } from '../EmptyState';
import { CourseTime } from '../CourseTime';

export const EngagementTab = ({ loadingPageViewsData, startDate, pageViewsStatistics, pageDataRows }) => {
  // FF
  const pageViewAlternateFF = useFeatureFlagPayload('page-view-query-alternate');

  // We started collecting unique user info on Jan 19. don't show the card if we don't have data i.e. before Jan 19. Same for page view data and March 30th.
  const verifyAfterDate = (d) => {
    return startDate > d;
  };

  const calculateCourseScore = () => {
    let score = 0;
    let runningTotal = 0;
    for (let question of pageViewsStatistics?.engagementOverview?.Questions) {
      runningTotal += Math.round((question.AnsweredCorrect / question.Total) * 100);
    }
    if (pageViewsStatistics?.engagementOverview?.Questions.length > 0) {
      score = Math.round(runningTotal / pageViewsStatistics?.engagementOverview?.Questions.length);
    }
    return score;
  };

  const countTotalResponses = () => {
    let runningTotal = 0;
    for (let question of pageViewsStatistics?.engagementOverview?.Questions) {
      runningTotal += question.Total;
    }
    return runningTotal;
  };

  const bartooltip = (tooltipItems) => {
    const index = tooltipItems?.[0]?.dataIndex;
    return pageViewsStatistics.data?.[index]?.PageName || 'No Page Name';
  };

  const aftertooltip = (tooltipItems) => {
    let visits = 0;
    if (tooltipItems) {
      visits = tooltipItems.formattedValue;
    }
    return `${visits} Visits`;
  };

  const engagementData = {
    totalPageVisits: pageViewAlternateFF?.value
      ? pageViewsStatistics?.engagementOverview?.TotalPageVisitsFromViewCounts
      : pageViewsStatistics?.engagementOverview?.TotalPageVisits,
    averagePagesPerVisitor: pageViewAlternateFF?.value
      ? Math.round(pageViewsStatistics?.engagementOverview?.AveragePagesPerVisitorFromViewCounts * 10) / 10
      : Math.round(pageViewsStatistics?.engagementOverview?.AveragePagesPerVisitor * 10) / 10,
  };

  return (
    <>
      {loadingPageViewsData && (
        <>
          <div
            className={css`
              display: flex;
              justify-content: center;
              margin-top: 80px;
            `}
          >
            <BriteLoader />
          </div>
        </>
      )}
      {!loadingPageViewsData &&
        pageViewsStatistics &&
        pageViewsStatistics.data &&
        pageViewsStatistics.data.length &&
        verifyAfterDate(new Date(2022, 2, 30)) && (
          <>
            <div
              className={css`
                display: flex;
                margin-top: 40px;
                align-items: center;
              `}
            >
              <Tooltip
                label="The total amount of page visits across your entire guide."
                description="The total includes a visitor viewing a page multiple times."
              >
                <div
                  className={css`
                    flex: 1;
                    background: #ffffff;
                    border: 1px solid ${colors.gray[300]};
                    border-radius: 8px;
                    padding: 16px 24px;
                    margin-right: 24px;
                  `}
                >
                  {engagementData?.totalPageVisits > 0 ? (
                    <>
                      <div
                        className={css`
                          font-family: 'Roboto';
                          font-style: normal;
                          font-weight: 400;
                          font-size: 16px;
                          line-height: 24px;
                          /* identical to box height, or 150% */
                          letter-spacing: 0.15px;
                          color: ${colors.gray[500]};
                        `}
                      >
                        Total Page Visits
                      </div>
                      <div
                        className={css`
                          font-family: 'Roboto';
                          font-style: normal;
                          font-weight: 700;
                          font-size: 34px;
                          line-height: 48px;
                          /* identical to box height, or 141% */
                          letter-spacing: 0.25px;
                          color: ${colors.black};
                        `}
                      >
                        {engagementData?.totalPageVisits?.toLocaleString()}
                      </div>
                    </>
                  ) : (
                    <EmptyStateSmall />
                  )}
                </div>
              </Tooltip>
              <Tooltip
                label="The average number of pages a visitor sees across your entire guide"
                description="The total includes a visitor viewing a page multiple times."
              >
                <div
                  className={css`
                    flex: 1;
                    background: #ffffff;
                    border: 1px solid ${colors.gray[300]};
                    border-radius: 8px;
                    padding: 16px 24px;
                    margin-right: 24px;
                  `}
                >
                  {engagementData?.averagePagesPerVisitor > 0 ? (
                    <>
                      <div
                        className={css`
                          font-family: 'Roboto';
                          font-style: normal;
                          font-weight: 400;
                          font-size: 16px;
                          line-height: 24px;
                          /* identical to box height, or 150% */
                          letter-spacing: 0.15px;
                          color: ${colors.gray[500]};
                        `}
                      >
                        Average Pages per Visitor
                      </div>
                      <div
                        className={css`
                          font-family: 'Roboto';
                          font-style: normal;
                          font-weight: 700;
                          font-size: 34px;
                          line-height: 48px;
                          /* identical to box height, or 141% */
                          letter-spacing: 0.25px;
                          color: ${colors.black};
                        `}
                      >
                        {engagementData?.averagePagesPerVisitor}
                      </div>
                    </>
                  ) : (
                    <EmptyStateSmall />
                  )}
                </div>
              </Tooltip>
              <Tooltip placement={'top'} label="The total amount of time spent on your guide by all visitors.">
                <div
                  className={css`
                    flex: 1;
                    background: #ffffff;
                    border: 1px solid ${colors.gray[300]};
                    border-radius: 8px;
                    padding: 16px 24px;
                    margin-right: 24px;
                  `}
                >
                  {pageViewsStatistics?.engagementOverview?.TotalVisitTimeSeconds > 0 ? (
                    <>
                      <div
                        className={css`
                          font-family: 'Roboto';
                          font-style: normal;
                          font-weight: 400;
                          font-size: 16px;
                          line-height: 24px;
                          /* identical to box height, or 150% */
                          letter-spacing: 0.15px;
                          color: ${colors.gray[500]};
                        `}
                      >
                        Total Visit Time
                      </div>
                      <CourseTime timeInSeconds={pageViewsStatistics?.engagementOverview?.TotalVisitTimeSeconds} />
                    </>
                  ) : (
                    <EmptyStateSmall />
                  )}
                </div>
              </Tooltip>
              <Tooltip label="The average amount of time a visitor spends on your guide." placement={'top'}>
                <div
                  className={css`
                    flex: 1;
                    background: #ffffff;
                    border: 1px solid ${colors.gray[300]};
                    border-radius: 8px;
                    padding: 16px 24px;
                  `}
                >
                  {pageViewsStatistics?.engagementOverview?.AverageTimePerVisitorSeconds > 0 ? (
                    <>
                      <div
                        className={css`
                          font-family: 'Roboto';
                          font-style: normal;
                          font-weight: 400;
                          font-size: 16px;
                          line-height: 24px;
                          /* identical to box height, or 150% */
                          letter-spacing: 0.15px;
                          color: ${colors.gray[500]};
                        `}
                      >
                        Average Time per Visitor
                      </div>
                      <CourseTime
                        timeInSeconds={pageViewsStatistics?.engagementOverview?.AverageTimePerVisitorSeconds}
                      />
                    </>
                  ) : (
                    <EmptyStateSmall />
                  )}
                </div>
              </Tooltip>
            </div>
            <div
              className={css`
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                /* identical to box height, or 133% */
                color: ${colors.black};
                margin-top: 40px;
                margin-bottom: 24px;
              `}
            >
              Visits by Page
            </div>
            <div
              className={css`
                margin-bottom: 40px;
                border-radius: 8px;
                border: 1px solid ${colors.gray[300]};
                padding: 24px;
              `}
            >
              {pageViewsStatistics?.data?.columnRows?.length > 0 ? (
                <ColumnChart
                  dataset={{
                    borderWidth: 0,
                    borderColor: colors.purple,
                    backgroundColor: colors.purple,
                  }}
                  min={0}
                  max={pageViewsStatistics.largestPageVisits < 10 ? pageViewsStatistics.largestPageVisits + 10 : ''}
                  library={{
                    interaction: {
                      intersect: false,
                      mode: 'index',
                    },
                    plugins: {
                      tooltip: {
                        backgroundColor: '#ffffff',
                        borderWidth: 2,
                        padding: 16,
                        borderColor: colors.purple,
                        titleColor: colors.black,
                        bodyColor: colors.gray[500],
                        titleFont: {
                          weight: 700,
                          family: 'Roboto',
                          size: 14,
                        },
                        callbacks: {
                          title: bartooltip,
                          label: aftertooltip,
                        },
                      },
                    },
                  }}
                  colors={[colors.purple]}
                  data={pageViewsStatistics.data.columnRows}
                />
              ) : (
                <EmptyState />
              )}
            </div>
            <div>
              <EnhancedTable rows={pageDataRows} />
            </div>
            <div
              className={css`
                margin-top: 40px;
              `}
            >
              <div
                className={css`
                  color: ${colors.black};
                  font-feature-settings: 'clig' off, 'liga' off;
                  font-family: Roboto;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 32px;
                `}
              >
                Your Custom Questions
              </div>
              <div
                className={css`
                  display: flex;
                  gap: 24px;
                  padding-top: 24px;
                `}
              >
                {pageViewsStatistics?.engagementOverview?.Questions.length !== 0 ? (
                  <>
                    <div
                      className={css`
                        border-radius: 8px;
                        border: 1px solid ${colors.gray[300]};
                        width: 263px;
                        padding: 24px;
                      `}
                    >
                      <div
                        className={css`
                          color: ${colors.gray[500]};
                          font-feature-settings: 'clig' off, 'liga' off;
                          /* Body - Dark */
                          font-family: Roboto;
                          font-size: 16px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 24px; /* 150% */
                          letter-spacing: 0.15px;
                        `}
                      >
                        Total Responses
                      </div>
                      <div
                        className={css`
                          color: ${colors.black};
                          font-feature-settings: 'clig' off, 'liga' off;
                          /* H1 */
                          font-family: Roboto;
                          font-size: 34px;
                          font-style: normal;
                          font-weight: 700;
                          line-height: 48px; /* 141.176% */
                          letter-spacing: 0.25px;
                          margin-bottom: 16px;
                        `}
                      >
                        {pageViewsStatistics?.engagementOverview?.Questions.length !== 0
                          ? `${countTotalResponses()}%`
                          : 'No data'}
                      </div>
                      <div
                        className={css`
                          color: ${colors.gray[500]};
                          font-feature-settings: 'clig' off, 'liga' off;
                          /* Body - Dark */
                          font-family: Roboto;
                          font-size: 16px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 24px; /* 150% */
                          letter-spacing: 0.15px;
                        `}
                      >
                        Average Score
                      </div>
                      <div
                        className={css`
                          color: ${colors.black};
                          font-feature-settings: 'clig' off, 'liga' off;
                          /* H1 */
                          font-family: Roboto;
                          font-size: 34px;
                          font-style: normal;
                          font-weight: 700;
                          line-height: 48px; /* 141.176% */
                          letter-spacing: 0.25px;
                        `}
                      >
                        {pageViewsStatistics?.engagementOverview?.Questions.length !== 0
                          ? `${calculateCourseScore()}%`
                          : 'No data'}
                      </div>
                    </div>
                    <div
                      className={css`
                        flex: 1;
                      `}
                    >
                      {pageViewsStatistics?.engagementOverview?.Questions.map((question, index) => {
                        return (
                          <div
                            key={index}
                            className={css`
                              border-radius: 8px;
                              border: 1px solid var(--Gray-300, #d1dae3);
                              padding-top: 16px;
                              padding-bottom: 16px;
                              padding-left: 24px;
                              padding-right: 40px;
                              display: flex;
                              margin-bottom: 16px;
                            `}
                          >
                            <div
                              className={css`
                                flex: 1;
                                padding-right: 40px;
                              `}
                            >
                              <div
                                className={css`
                                  color: ${colors.black};
                                  font-feature-settings: 'clig' off, 'liga' off;
                                  /* Body - Regular */
                                  font-family: Roboto;
                                  font-size: 16px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 24px; /* 150% */
                                  letter-spacing: 0.15px;
                                `}
                              >
                                {question.QuestionText}
                              </div>
                              <div
                                className={css`
                                  color: ${colors.gray[500]};
                                  font-feature-settings: 'clig' off, 'liga' off;
                                  /* Caption */
                                  font-family: Roboto;
                                  font-size: 14px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 24px; /* 171.429% */
                                  letter-spacing: 0.15px;
                                  padding-top: 8px;
                                `}
                              >
                                {question.Total} Responses
                              </div>
                            </div>
                            <div
                              className={css`
                                border-left: 1px solid ${colors.gray[300]};
                                padding-left: 40px;
                                width: 100px;
                              `}
                            >
                              <div
                                className={css`
                                  color: ${colors.black};
                                  text-align: center;
                                  font-feature-settings: 'clig' off, 'liga' off;
                                  /* H2 */
                                  font-family: Roboto;
                                  font-size: 24px;
                                  font-style: normal;
                                  font-weight: 700;
                                  line-height: 32px;
                                `}
                              >
                                {Math.round((question.AnsweredCorrect / question.Total) * 100)}%
                              </div>
                              <div
                                className={css`
                                  color: ${colors.gray[500]};
                                  text-align: center;
                                  font-feature-settings: 'clig' off, 'liga' off;
                                  /* Caption */
                                  font-family: Roboto;
                                  font-size: 14px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 24px; /* 171.429% */
                                  letter-spacing: 0.15px;
                                `}
                              >
                                Correct
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <div
                    className={css`
                      border-radius: 8px;
                      border: 1px solid ${colors.gray[300]};
                      width: 100%;
                      padding: 24px;
                    `}
                  >
                    <EmptyState />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
    </>
  );
};
