import { createContext, useReducer, useRef } from 'react';
import { Editor } from '../../Editor';
import { toolKitReducer, initialToolkit } from './tool-kit-reducer';

export const ToolKitContext = createContext();

export const ToolKit = (props) => {
  const images = useRef({});
  const [data, dispatch] = useReducer(toolKitReducer, initialToolkit);

  const toolKitProps = {
    data,
    dispatch,
    images,
  };

  return (
    <ToolKitContext.Provider value={toolKitProps}>
      <Editor {...props} />
    </ToolKitContext.Provider>
  );
};

ToolKit.getSelection = (toolkit) => toolkit?.data?.selection || {};
ToolKit.select = (toolkit, segment) => {
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'selection',
    payload: {
      id: segment?.id,
      type: segment?.type,
      elements: segment?.elements,
    },
  });
};
ToolKit.deselect = (toolkit) => {
  toolkit.dispatch({
    type: 'RESET_PROPERTY',
    property: 'selection',
  });
};

ToolKit.getDrag = (toolkit) => toolkit?.data?.drag || {};
ToolKit.startDrag = (toolkit, segment, type) => {
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'drag',
    payload: {
      ...initialToolkit?.drag,
      type,
      isDragging: true,
      source: segment,
    },
  });
};
ToolKit.drop = (toolkit, segment, edge) => {
  toolkit.dispatch({
    type: 'UPDATE_PROPERTY',
    property: 'drag',
    payload: {
      destination: segment,
      isComplete: true,
      isDragging: false,
      edge,
    },
  });
};
ToolKit.resetDrag = (toolkit) => {
  toolkit.dispatch({
    type: 'RESET_PROPERTY',
    property: 'drag',
  });
};
