import { useEffect, useMemo, useState } from 'react';
import { useQueryAPI } from '../react-query';
import { CustomAxios } from '../redux/axios/axios';
import { useStateSync } from './use-state-sync';

export const useInfiniteScrollPagination = ({ url: baseUrl, ...rest }) => {
  const [url, setUrl, isSynced] = useStateSync(baseUrl, [baseUrl, rest?.params?.query]);
  const [next, setNext] = useState('');
  const [paginatedData, setPaginatedData] = useState({});
  const [paginatedList, setPaginatedList] = useState([]);

  const query = useMemo(() => {
    if (isSynced) {
      return rest?.params?.query || {};
    } else {
      return {};
    }
  }, [url, rest?.params?.query]);

  const queryProps = useQueryAPI({
    ...rest,
    url,
    params: { ...(rest?.params || {}), query },
    onSuccess: (data, constructedUrl) => {
      let nextData = {};
      if (isSynced) {
        nextData = { [constructedUrl]: data?.Data || [] };
      } else {
        nextData = { ...paginatedData, [constructedUrl]: data?.Data || [] };
      }
      setPaginatedData(nextData);
      setNext(data?.Next);
      if (rest?.onSuccess) {
        rest?.onSuccess(nextData);
      }
    },
    onMount: (data, constructedUrl) => {
      if (isSynced) {
        setPaginatedData({ [constructedUrl]: data?.Data || [] });
      }
    },
    initialData: [],
  });

  const fetchNext = () => setUrl(next);

  useEffect(() => {
    const paginatedList = Object.values(paginatedData || {}).reduce(
      (prev, item) => (item ? [...prev, ...item] : prev),
      []
    );
    setPaginatedList(paginatedList);
  }, [paginatedData]);

  const refetch = async () => {
    try {
      const list = Object.keys(paginatedData || {});
      const promises = await Promise.allSettled(list.map((item) => CustomAxios.get(item)));
      const nextData = promises.reduce((prev, item) => {
        if (item.status === 'fulfilled') {
          try {
            const url = new URL(item?.value?.config?.url);
            return { ...prev, [url?.pathname + url?.search]: item?.value?.data?.Data };
          } catch (err) {
            console.warn(err);
            return prev;
          }
        }
        return prev;
      }, {});
      setPaginatedData({ ...paginatedData, ...nextData });
    } catch (err) {
      console.warn(err);
      throw err;
    }
  };

  return {
    ...queryProps,
    refetch,
    fetchNext,
    paginatedList,
    setPaginatedList,
    data: paginatedData,
    hasMore: !!next,
  };
};
